import React, { useRef, useEffect, useState, Fragment } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import ContentWrapper from "../../styles/contentWrapper"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"

const StyledSection = motion.custom(styled.section`
  width: 100%;
  height: auto;
  margin: 50px auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 100px auto 50px;
  }
  background: ${({ theme }) => theme.colors.background};
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 2rem;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .section-title {
      margin-bottom: 2rem;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
    }
  }
`

const ContactHeading = styled.h3`
  padding-bottom: 1rem;
  font-weight: 500;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 4rem;
  }
`

const ContactSection = ({ isMobile }) => {
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const tControls = useAnimation()
  const iControls = useAnimation()
  const tRef = useRef()

  useEffect(() => {
    tControls.start({ opacity: 1, y: 0 })
    iControls.start({ opacity: 1, x: 0 })
  }, [tControls, iControls])

  const submitForm = ev => {
    setLoading(true)
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
      setLoading(false)
    }
    xhr.send(data)
  }

  return (
    <StyledSection isMobile={isMobile}>
      <StyledContentWrapper isMobile={isMobile}>
        <motion.div
          className="inner-wrapper"
          ref={tRef}
          initial={{ opacity: 0, y: 20 }}
          animate={tControls}
        >
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <ContactHeading isMobile={isMobile}>
              Looking to hire a full stack software engineer? Send me a message.
              I’d love to discuss what you’re working on.
            </ContactHeading>
            {status === "SUCCESS" && (
              <Alert variant="success">Success! Your message was sent.</Alert>
            )}
            {status === "ERROR" && (
              <Alert variant="danger">Ooops! There was an error.</Alert>
            )}
            <Form
              onSubmit={submitForm}
              action="https://formspree.io/f/mrgoygej"
              method="POST"
              style={{ width: "100%" }}
            >
              {isMobile ? (
                <Fragment>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control as="input" name="name" type="text" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control as="input" name="email" type="email" />
                  </Form.Group>
                </Fragment>
              ) : (
                <Form.Row>
                  <Form.Group as={Col} controlId="formBasicName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control as="input" name="name" type="text" />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control as="input" name="email" type="email" />
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Group controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  type="text"
                  rows={6}
                />
              </Form.Group>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  as="button"
                  variant="dark"
                  size="lg"
                  type="submit"
                  style={{ minWidth: isMobile ? "100%" : "150px" }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner animation="border" size="sm" />
                      <div style={{ paddingLeft: ".25rem" }}>Sending...</div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default ContactSection
