import React, { useState, useEffect } from "react"
import { useWindowSize } from "../hooks"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactSection from "../components/sections/contactSection"

const Contact = () => {
  const dimensions = useWindowSize()
  const [screen, setScreen] = useState(null)
  useEffect(() => {
    if (dimensions) {
      setScreen(dimensions.windowWidth)
    }
  }, [dimensions])
  return (
    <Layout isMobile={screen < 1200}>
      <SEO title="Contact" />
      <ContactSection isMobile={screen < 1200} />
    </Layout>
  )
}

export default Contact
